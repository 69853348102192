import React from "react";
import "../../App.css";
import Footer from "../Footer";
import Cards from "../Cards";
import ImageSlider from "../ImageSliderService";

export default function Services() {
  return (
    <>
      <ImageSlider />

      <Cards />
      <Footer />
    </>
  );
}

import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";
import { ButtonS } from "./ButtonS";
function HeroSection() {
  return (
    <div className="hero-container">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}

      <h1 style={{ color: "#40ecff" }}>Let us Connect </h1>
      <p style={{ textAlign: "center" }}>
        Let us streamline the process for you with zero delays
      </p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          Products
        </Button>
        <ButtonS
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          onClick={console.log("hey")}
        >
          Services
          <i className="far fa-play-circle" />
        </ButtonS>
      </div>
    </div>
  );
}

export default HeroSection;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageSliderService.css"; // Create this CSS file for styling

const ImageSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true, // Use fade transition instead of sliding
    autoplay: true, // Enable auto-play
    autoplaySpeed: 5000, // Set the speed of auto-play in milliseconds (adjust as needed)
  };

  const images = [
    {
      id: 1,
      src: "img/img-10.jpg",
      description:
        "Consultancy Service on information and Communication technology ",
    },
    {
      id: 2,
      src: "img/img-2.jpg",
      description: "Telecommunication Backbone Systems and Infrastructure ",
    },
    {
      id: 3,
      src: "img/img-9.jpg",
      description:
        "Networking Infrastructure and Security, Video, Voice, Data and Specialized Systems ",
    },
    {
      id: 4,
      src: "img/img-8.jpg",
      description:
        "Effective survey design, meticulous planning, and precise installation ",
    },
    {
      id: 5,
      src: "img/img-7.png",
      description:
        "installation of solar structures and diverse power systems ",
    },
    // Add more images as needed "
  ];

  return (
    <Slider {...settings}>
      {images.map((image) => (
        <div key={image.id} className="slider-item zigzag-container">
          <div className="slanted-line">
            <img src={image.src} alt={image.description} />
            <div className="overlay">
              <p>{image.description}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default ImageSlider;

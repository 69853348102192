// import React from 'react';
// import '../../App.css';

// export default function Products() {
//   return <h1 className='products'>PRODUCTS</h1>;
// }

import React, { Component } from "react";
import "../productpage.css"; // Import your CSS file
import Footer from "../Footer";
import ProductSlider from "../ProductSlider";
class ProductPage extends Component {
  constructor() {
    super();
    this.state = {
      products: [
        {
          id: 1,
          name: "IT Infrastructure Planning",
          category: "Consultancy Service on ICT",
        },
        {
          id: 2,
          name: "Network Design and Optimization",
          category: "Consultancy Service on ICT",
        },
        {
          id: 3,
          name: "Cybersecurity Solutions",
          category: "Consultancy Service on ICT",
        },
        {
          id: 4,
          name: "Cloud Computing and Virtualization",
          category: "Consultancy Service on ICT",
        },
        {
          id: 5,
          name: "Data Analytics and Business Intelligence",
          category: "Consultancy Service on ICT",
        },
        {
          id: 6,
          name: "Software Development and Customization",
          category: "Consultancy Service on ICT",
        },
        {
          id: 7,
          name: "Uninterruptible Power Supply (UPS) Systems",
          category: "Supply of Power-Related Items",
        },
        {
          id: 8,
          name: "Voltage Stabilizers",
          category: "Supply of Power-Related Items",
        },
        {
          id: 9,
          name: "Solar Power Systems",
          category: "Supply of Power-Related Items",
        },
        {
          id: 10,
          name: "Batteries and Inverters",
          category: "Supply of Power-Related Items",
        },
        {
          id: 11,
          name: "Power Distribution Units (PDUs)",
          category: "Supply of Power-Related Items",
        },
        {
          id: 12,
          name: "Surge Protectors",
          category: "Supply of Power-Related Items",
        },
        {
          id: 13,
          name: "Genuine Generator Spare Parts",
          category: "Generator Spare Parts",
        },
        {
          id: 14,
          name: "Regular Maintenance Kits",
          category: "Generator Spare Parts",
        },
        {
          id: 15,
          name: "Filters, Belts, and Hoses",
          category: "Generator Spare Parts",
        },
        {
          id: 16,
          name: "Control Panels and Sensors",
          category: "Generator Spare Parts",
        },
        {
          id: 17,
          name: "Exhaust Systems and Mufflers",
          category: "Generator Spare Parts",
        },
        { id: 18, name: "Diesel Generators", category: "Generators" },
        { id: 19, name: "Gas Generators", category: "Generators" },
        { id: 20, name: "Portable Generators", category: "Generators" },
        { id: 21, name: "Industrial Generators", category: "Generators" },
        { id: 22, name: "Standby Generators", category: "Generators" },
      ],
    };
  }

  renderSubCategory(category) {
    const subCategories = [
      ...new Set(
        this.state.products
          .filter((product) => product.category === category)
          .map((product) => product.name)
      ),
    ];

    return (
      <>
        {" "}
        <h2 className="category">{category}</h2>
        <div className="product-list" key={category}>
          <ul>
            {subCategories.map((subCategory) => (
              <>
                <li key={subCategory}>
                  {" "}
                  <i class="fa fa-chevron-circle-down" />
                  {subCategory}
                </li>
              </>
            ))}
          </ul>
        </div>
      </>
    );
  }

  render() {
    return (
      <>
        <ProductSlider />
        <div className="product-page">
          <div className="computer-layout">
            {this.renderSubCategory("Consultancy Service on ICT")}
          </div>
          <div className="computer-layout">
            {this.renderSubCategory("Supply of Power-Related Items")}
          </div>
          <div className="computer-layout">
            {this.renderSubCategory("Generator Spare Parts")}
          </div>
          <div className="computer-layout">
            {this.renderSubCategory("Generators")}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default ProductPage;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css"; // Your custom styles for the slider

const products = [
  {
    id: 1,
    name: "Power-Related Items",
    image: "img/img-13.jpeg",
    description: "Supply of Power-Related Items",
  },
  {
    id: 2,
    name: "Generators",
    image: "img/img-14.jpeg",
    description: "Generator & Generator Spare Parts",
  },
  {
    id: 3,
    name: "Computers",
    image: "img/img-15.jpeg",
    description: "Supply of computers and accessories",
  },

  // Add more products as needed
];

const ProductSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000,
  };

  return (
    <div className="product-slider-container">
      <Slider {...settings}>
        {products.map((product) => (
          <div key={product.id} className="product-slide">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
            />
            <div className="product-caption">
              <h3>{product.name}</h3>
              <p>{product.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductSlider;

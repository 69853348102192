import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

function Cards() {
  return (
    <div className="cards">
      <h1>Check our Services!</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="img/img-2.jpg"
              text="Well Experienced in the design, integration and installation of a range of telecom products "
              label="Telecommunication"
              path="/services"
            />
            <CardItem
              src="img/img-3.jpg"
              text="Provides technology solutions and power solutions to Embassies, Universities, Telecom operator's and EEP to support their power needs."
              label="Utilities"
              path="/services"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="img/img-4.jpg"
              text="Installation of manholes, demolition of buildings, and erection of towers "
              label="Civil Works"
              path="/services"
            />
            <CardItem
              src="img/img-5.jpg"
              text="Telecom expansion survey and delivering Low-Level Design (LLD) and High-Level Design (HLD) plans"
              label="Survey and planning"
              path="/products"
            />
            <CardItem
              src="img/img-6.jpg"
              text="Provide Data Center, Site & Power Business Unit builds data centers from ground up"
              label="Data Center"
              path="/sign-up"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;

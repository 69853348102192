import React from "react";
import "./Footer.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import companyIcon from "../images/simplelogo.jpg";
const phoneNumber = "+251913053310"; // Replace with your WhatsApp phone number
const whatsappLink = `https://wa.me/${phoneNumber}`;
function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading">
          WEBEthio TECHNOLOGY PLC. Transforming the Future of Technology and
          Power Solutions
        </p>
        <p className="footer-subscription-text">
          please contact{" "}
          <a href="info@webtech.et" style={{ color: "#E0E0E0" }}>
            info@webethio.com{" "}
          </a>
          or{" "}
          <a href="girum@webtech.et" style={{ color: "#E0E0E0" }}>
            girum@webethio.com
          </a>
        </p>
        <div className="input-areas">
          <form>
            <input
              className="footer-input"
              name="email"
              type="email"
              placeholder="Your Message"
            />
            <Button buttonStyle="btn--outline">Contact Us</Button>
          </form>
        </div>
      </section>
      <div class="footer-links">
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <h2>About Us</h2>
            <Link to="">Testimonials</Link>
            <Link to="">Careers</Link>

            <Link to="">Terms of Service</Link>
          </div>
          <div class="footer-link-items">
            <h2>Contact Us</h2>
            <Link to="">Contact</Link>
            <Link to="">Support</Link>
            <Link to="">Destinations</Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <h2>Videos</h2>
            <Link to="">Submit Video</Link>
            <Link to="">Users</Link>
            <Link to="">Agency</Link>
          </div>
          <div class="footer-link-items">
            <h2>Social Media</h2>
            <Link to={whatsappLink}>WhatsApp</Link>
            <Link to="">Facebook</Link>

            <Link to="">Twitter</Link>
          </div>
        </div>
      </div>
      <section class="social-media">
        <div class="social-media-wrap">
          <div class="footer-logo">
            <Link to="/" className="navbar-logo">
              WEBEthio
              <img
                alt="logo"
                src={companyIcon}
                className=" img-fluid col-lg-6"
                style={{ maxHeight: "50px" }}
              />
              {/*<i class="fab fa-typo3" />*/}
            </Link>
          </div>
          <small class="website-rights">WEBEthio TECHNOLOGY PLC © 2023</small>
          <div class="social-icons">
            <Link
              class="social-icon-link facebook"
              to=""
              target="_blank"
              aria-label="Facebook"
            >
              <i class="fab fa-facebook-f" />
            </Link>
            <Link
              class="social-icon-link instagram"
              to=""
              target="_blank"
              aria-label="Instagram"
            >
              <i class="fab fa-instagram" />
            </Link>
            <Link
              class="social-icon-link youtube"
              to=""
              target="_blank"
              aria-label="Youtube"
            >
              <i class="fab fa-youtube" />
            </Link>
            <Link
              class="social-icon-link twitter"
              to=""
              target="_blank"
              aria-label="Twitter"
            >
              <i class="fab fa-twitter" />
            </Link>
            <Link
              class="social-icon-link twitter"
              to=""
              target="_blank"
              aria-label="LinkedIn"
            >
              <i class="fab fa-linkedin" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
